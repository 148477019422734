"use client"
import { Button, Container, Stack } from "@mui/material"
import { Page } from "@tc/ui-shared/components"
import { useSearchQuery } from "@tc/ui-shared/hooks"
import { signIn } from "next-auth/react"
import { Suspense } from "react"

const SigninButton = () => {
  const { query } = useSearchQuery()
  const handleSignin = async () => {
    const callbackUrl = (query?.callbackUrl as string) || "/"
    await signIn("cognito", {
      callbackUrl,
      redirect: true,
    })
  }
  return (
    <Button onClick={handleSignin} size="large" fullWidth variant="contained">
      Login with MPI account
    </Button>
  )
}
const Signin = () => {
  return (
    <Page
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="xs">
        <Stack>
          <Suspense
            fallback={
              <Button size="large" fullWidth variant="contained">
                Login with MPI account
              </Button>
            }
          >
            <SigninButton />
          </Suspense>
        </Stack>
      </Container>
    </Page>
  )
}
export default Signin
